var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
      _vm.$t(
        ("Global." + (_vm.$can(_vm.$acl.action.Update, _vm.$acl.subjects.Users)
            ? 'EditSubject'
            : 'ViewSubject')),
        { subject: _vm.$t('Management.Users.Users') }
      )
    ),expression:"\n      $t(\n        `Global.${\n          $can($acl.action.Update, $acl.subjects.Users)\n            ? 'EditSubject'\n            : 'ViewSubject'\n        }`,\n        { subject: $t('Management.Users.Users') },\n      )\n    ",modifiers:{"hover":true,"top":true}}],staticClass:"hover-primary",attrs:{"icon":_vm.$can(_vm.$acl.action.Create, _vm.$acl.subjects.Users) ? 'Edit2Icon' : 'EyeIcon',"size":"16"},on:{"click":_vm.openSideBar}}),_c('Form',{ref:"form",attrs:{"user":_vm.updatedData,"submit-btn-text":((_vm.$t('Global.Save')) + " " + (_vm.$t('Management.Users.Users'))),"can":_vm.$can(_vm.$acl.action.Create, _vm.$acl.subjects.Users),"header":_vm.$t('Management.Users.Users') + ' ' + _vm.$tc('Global.EditSubject')},on:{"submit":_vm.submit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }