var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{ref:"sidebar",attrs:{"shadow":"","backdrop":"","right":"","no-close-on-backdrop":"","no-close-on-esc":"","no-enforce-focus":"","bg-variant":"white","width":"420px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',{staticClass:"my-1 mx-2 font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.header)+" ")])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between m-2"},[(_vm.can)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('Global.Save'))+" ")]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.sidebarVisible = false}}},[_vm._v(" "+_vm._s(_vm.$t('Global.Retreat'))+" ")])],1)]},proxy:true}])},[_c('validation-observer',{ref:"rules",staticClass:"px-3 mt-2",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[(_vm.user)?_c('section',[_c('b-form-group',{attrs:{"label":_vm.$t('Management.Users.Username'),"label-for":"username"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Management.Users.Username'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","placeholder":_vm.$t('Global.Write', { subject: _vm.$t('Management.Users.Username') }),"state":errors.length > 0 ? false : null,"autocomplete":"off","disabled":!_vm.can},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,200394288)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Management.Users.Firstname'),"label-for":"firstname"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Management.Users.Firstname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstname","placeholder":_vm.$t('Global.Write', {
                subject: _vm.$t('Management.Users.Firstname'),
              }),"state":errors.length > 0 ? false : null,"autocomplete":"off","disabled":!_vm.can},model:{value:(_vm.user.firstname),callback:function ($$v) {_vm.$set(_vm.user, "firstname", $$v)},expression:"user.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2354705751)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Management.Users.Lastname'),"label-for":"lastname"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Management.Users.Lastname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastname","placeholder":_vm.$t('Global.Write', { subject: _vm.$t('Management.Users.Lastname') }),"state":errors.length > 0 ? false : null,"autocomplete":"off","disabled":!_vm.can},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2503825355)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Management.Users.Role'),"label-for":"role"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Management.Users.Role'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{class:{
              invalid: errors.length > 0,
            },attrs:{"id":"role","placeholder":_vm.$t('Global.Select', { subject: _vm.$t('Management.Users.Role') }),"options":_vm.Roles,"disabled":!_vm.can},scopedSlots:_vm._u([{key:"option",fn:function(ref){
            var label = ref.label;
return [_c('div',[_vm._v(_vm._s(_vm.$t(("Auth.Roles." + label))))])]}}],null,true),model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("Global.NothingFound")))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,492095446)})],1),_c('b-form-group',{attrs:{"label":_vm.$tc('Management.Users.Email'),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":_vm.$tc('Management.Users.Email'),"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","placeholder":_vm.$t('Global.Write', { subject: _vm.$tc('Management.Users.Email') }),"state":errors.length > 0 ? false : null,"autocomplete":"off","disabled":!_vm.can},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,758725545)})],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }