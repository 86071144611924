<template>
  <b-sidebar
    ref="sidebar"
    shadow
    backdrop
    right
    no-close-on-backdrop
    no-close-on-esc
    no-enforce-focus
    bg-variant="white"
    width="420px"
  >
    <template #header>
      <h3 class="my-1 mx-2 font-weight-bolder">
        {{ header }}
      </h3>
    </template>
    <validation-observer
      ref="rules"
      tag="form"
      class="px-3 mt-2"
      @submit.prevent="submit"
    >
      <section v-if="user">
        <b-form-group
          :label="$t('Management.Users.Username')"
          label-for="username"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Users.Username')"
            rules="required"
          >
            <b-form-input
              id="username"
              v-model="user.username"
              :placeholder="
                $t('Global.Write', { subject: $t('Management.Users.Username') })
              "
              :state="errors.length > 0 ? false : null"
              autocomplete="off"
              :disabled="!can"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          :label="$t('Management.Users.Firstname')"
          label-for="firstname"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Users.Firstname')"
            rules="required"
          >
            <b-form-input
              id="firstname"
              v-model="user.firstname"
              :placeholder="
                $t('Global.Write', {
                  subject: $t('Management.Users.Firstname'),
                })
              "
              :state="errors.length > 0 ? false : null"
              autocomplete="off"
              :disabled="!can"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          :label="$t('Management.Users.Lastname')"
          label-for="lastname"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Users.Lastname')"
            rules="required"
          >
            <b-form-input
              id="lastname"
              v-model="user.lastname"
              :placeholder="
                $t('Global.Write', { subject: $t('Management.Users.Lastname') })
              "
              :state="errors.length > 0 ? false : null"
              autocomplete="off"
              :disabled="!can"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group :label="$t('Management.Users.Role')" label-for="role">
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Users.Role')"
            rules="required"
          >
            <v-select
              id="role"
              v-model="user.role"
              :placeholder="
                $t('Global.Select', { subject: $t('Management.Users.Role') })
              "
              :class="{
                invalid: errors.length > 0,
              }"
              :options="Roles"
              :disabled="!can"
            >
            <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
              <template #option="{ label }">
                <div>{{ $t(`Auth.Roles.${label}`) }}</div>
              </template>
              <!-- <template #selected-option="{ label }">
                <div>{{ $t(`Auth.Roles.${label}`) }}</div>
              </template> -->
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group :label="$tc('Management.Users.Email')" label-for="email">
          <validation-provider
            #default="{ errors }"
            :name="$tc('Management.Users.Email')"
            rules="email"
          >
            <b-form-input
              id="email"
              v-model="user.email"
              :placeholder="
                $t('Global.Write', { subject: $tc('Management.Users.Email') })
              "
              :state="errors.length > 0 ? false : null"
              autocomplete="off"
              :disabled="!can"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </section>
    </validation-observer>
    <template #footer>
      <div class="d-flex justify-content-between m-2">
        <b-button
          v-if="can"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="submit"
        >
          {{ $t('Global.Save') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          @click="sidebarVisible = false"
        >
          {{ $t('Global.Retreat') }}
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import vSelect from 'vue-select'
import { BButton, BSidebar, BFormInput, BFormGroup } from 'bootstrap-vue'
import { Roles } from '@/data/enums'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { preventUnsavedChangesPopupLevel } from '@/utility/mixins/preventUnsavedChanges'

export default {
  components: {
    BButton,
    BSidebar,
    BFormInput,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [preventUnsavedChangesPopupLevel],

  props: {
    user: {
      type: Object,
      default: () => null,
    },
    can: {
      type: Boolean,
      required: true,
    },
    header: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    required,
    Roles,
  }),

  methods: {
    reset() {
      this.$emit('reset')
      this.$refs.rules.reset()
    },
    async submit() {
      const valid = await this.$refs.rules.validate()

      if (valid) {
        this.$emit('submit')
        return
      }
      this.$alert.invalid()
    },
  },
}
</script>
