<template>
  <!-- table -->
  <vue-good-table
    :columns="columns"
    :rows="rows"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }"
    :pagination-options="{
      enabled: true,
      perPage: pageLength,
    }"
  >
    <div slot="emptystate" class="text-center">
      {{ $t('Global.Emptystate') }}
    </div>
    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field === 'status'">
        <b-form-checkbox
          v-model="props.row.active"
          class="custom-control-success"
          name="check-button"
          switch
          @change="$emit('toggle-status', props.row)"
        >
          <span class="switch-icon-left">
            <feather-icon icon="UnlockIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="LockIcon" />
          </span>
        </b-form-checkbox>
      </span>
      <!-- Column: Action -->
      <span v-else-if="props.column.field === 'action'" class="action">
        <Update :user="props.row" />
        <span
          v-if="$can($acl.action.Delete, $acl.subjects.Users)"
          v-b-tooltip.hover.top="
            $t('Global.DeleteSubject', {
              subject: $t('Management.Users.Users'),
            })
          "
        >
          <feather-icon
            icon="TrashIcon"
            size="16"
            class="hover-red"
            @click="$emit('delete', props.row)"
          />
        </span>
        <span
          v-if="
            props.row.hasPassword &&
            $can($acl.action.Update, $acl.subjects.Users)
          "
          v-b-tooltip.hover.top="$t('Management.Users.ResetPassword')"
        >
          <feather-icon
            icon="KeyIcon"
            size="16"
            class="hover-orange"
            @click="resetPassword(props.row)"
          />
        </span>
        <span
          v-else-if="$can($acl.action.Update, $acl.subjects.Users)"
          v-b-tooltip.hover.top="$t('Management.Users.GenerateLinkText')"
        >
          <feather-icon
            icon="Link2Icon"
            size="16"
            class="hover-orange"
            @click="genPassword(props.row)"
          />
        </span>
      </span>

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>

    <!-- pagination -->
    <template slot="pagination-bottom" slot-scope="props">
      <div class="d-flex justify-content-between flex-wrap px-2 pb-2">
        <div class="d-flex align-items-center mb-0 mt-1 flex-wrap">
          <span class="text-nowrap">
            {{ $t('Global.ShowingOneOf') }}
          </span>
          <v-select
            v-model="pageLength"
            class="mx-1"
            style="min-width: 75px"
            :clearable="false"
            :options="['10', '15', '30']"
            @input="value => props.perPageChanged({ currentPerPage: value })"
          />
          <span class="text-nowrap">{{ $t('Global.EntriesShown') }}</span>
        </div>
        <div>
          <b-pagination
            :value="1"
            :total-rows="props.total"
            :per-page="pageLength"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @input="value => props.pageChanged({ currentPage: value })"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </template>
  </vue-good-table>
</template>

<script>
import { BPagination, BFormCheckbox } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { logout } from '@/auth/utils'
import Update from './update.vue'

export default {
  components: {
    VueGoodTable,
    BFormCheckbox,
    vSelect,
    BPagination,
    Update,
  },

  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    searchTerm: {
      type: String,
      default: () => '',
    },
  },

  data() {
    return {
      pageLength: 30,
      columns: [
        {
          label: this.$t('Management.Users.Username'),
          field: 'username',
        },
        {
          label: this.$t('Management.Users.Firstname'),
          field: 'firstname',
        },
        {
          label: this.$t('Management.Users.Lastname'),
          field: 'lastname',
        },
        {
          label: this.$t('Management.Users.Role'),
          field: 'role',
          formatFn: role => this.$t(`Auth.Roles.${role}`),
        },
        {
          label: this.$t('Global.Status'),
          field: 'status',
          sortable: false,
        },
        {
          label: this.$t('Global.Action'),
          field: 'action',
          sortable: false,
        },
      ],
    }
  },

  methods: {
    async resetAppKey({ id, firstname, lastname }) {
      const subject = `${firstname} ${lastname}`
      try {
        const messageVNode = this.$createElement('div', {
          class: ['p-1'],
          domProps: {
            innerHTML: this.$t('Management.Users.ResetAppQuestion', {
              subject,
            }),
            style: 'font-size: 16px',
          },
        })

        const choice = await this.$bvModal.msgBoxConfirm([messageVNode], {
          title: this.$t('Management.Users.ResetAppKey'),
          size: 'sm',
          okVariant: 'primary',
          cancelVariant: 'outline-secondary',
          okTitle: this.$t('Global.Deny'),
          cancelTitle: this.$t('Global.Retreat'),
          hideHeaderClose: false,
          centered: true,
          'body-class': 'p-2',
        })
        if (!choice) return
      } catch {
        return
      }

      try {
        await this.$axios.get(`auth/app/strip-access-key/${id}`)
        this.$alert.success(
          this.$t('Management.Users.ResetAppPasswordResponse', {
            subject,
          }),
        )
        this.$emit('reset-app-key', id)
      } catch {
        this.$alert.error()
      }
    },
    async genAppKey({ id }) {
      const getLink = async () => {
        try {
          return await this.$axios.get(`auth/app/activation-token/${id}`)
        } catch (error) {
          this.$alert.error()
          return null
        }
      }

      const { data } = await getLink()

      if (!data) return

      try {
        await this.$copyText(data)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Global.Copied'),
            icon: 'BellIcon',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Global.CanCopy'),
            icon: 'BellIcon',
          },
        })
      }
    },
    async genPassword({ id }) {
      const getLink = async () => {
        try {
          return await this.$axios.get(`auth/generate-password-link-web/${id}`)
        } catch (error) {
          this.$alert.error()
          return null
        }
      }

      const { data } = await getLink()

      if (!data) return

      try {
        await this.$copyText(data)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Global.Copied'),
            icon: 'BellIcon',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Global.CanCopy'),
            icon: 'BellIcon',
          },
        })
      }
    },

    async resetPassword({ id, firstname, lastname }) {
      const subject = `${firstname} ${lastname}`
      try {
        const messageVNode = this.$createElement('div', {
          class: ['p-1'],
          domProps: {
            innerHTML: this.$t('Management.Users.ResetQuestion', { subject }),
            style: 'font-size: 16px',
          },
        })

        const choice = await this.$bvModal.msgBoxConfirm([messageVNode], {
          title: this.$t('Management.Users.ResetPassword'),
          size: 'sm',
          okVariant: 'primary',
          cancelVariant: 'outline-secondary',
          okTitle: this.$t('Global.Reset'),
          cancelTitle: this.$t('Global.Retreat'),
          hideHeaderClose: false,
          centered: true,
          'body-class': 'p-2',
        })
        if (!choice) return
      } catch {
        return
      }

      try {
        await this.$axios.get(`users/reset-password/${id}`)
        this.$alert.success(
          this.$t('Management.Users.ResetPasswordResponse', {
            subject,
          }),
        )
        this.$emit('reset-password', id)
        const user = JSON.parse(localStorage.getItem('user'))

        if (user && user.id === id) {
          const { data } = await this.$axios.get(
            `auth/generate-password-link-web/${id}`,
          )
          logout()
          window.location.href = data
        }
      } catch {
        this.$alert.error()
      }
    },
  },
}
</script>
