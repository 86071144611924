<template>
  <section>
    <b-card>
      <h3 class="mb-1 font-weight-bolder">
        {{ $t('Global.Filters') }}
      </h3>
      <b-row>
        <b-col lg="3" md="4" sm="12">
          <label>
            {{ $t('Management.Users.Role') }}
          </label>
          <v-select
            v-model="filter.role"
            :placeholder="$t('Global.Filter')"
            :options="Roles"
          >
          <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
            <template #option="{ label }">
              <div>{{ $t(`Auth.Roles.${label}`) }}</div>
            </template>
            <template #selected-option="{ label }">
              <div>{{ $t(`Auth.Roles.${label}`) }}</div>
            </template>
          </v-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <div
        class="custom-search d-flex justify-content-between align-items-center mb-2 px-2 pt-2"
      >
        <h2 class="m-0 font-weight-bolder">
          {{ $t('Global.All') }} {{ $t('Management.Users.Users') }}
        </h2>
        <div class="d-flex">
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('Global.Search')"
              type="text"
              class="d-inline-block search"
            />
          </div>
          <Create
            v-if="$can($acl.action.Create, $acl.subjects.Users)"
            @add="addEntry"
          />
        </div>
      </div>

      <Table
        :rows="rowData"
        :search-term="searchTerm"
        @delete="deleteRow"
        @reset-password="resetPassword"
        @reset-app-key="resetAppKey"
        @toggle-status="toggleStatus"
      />
    </b-card>
  </section>
</template>

<script>
import { BFormInput, BCard, BRow, BCol } from 'bootstrap-vue'
import vSelect from 'vue-select'

import { logout } from '@/auth/utils'
import { Roles } from '@/data/enums'
import deleteEntry from '@/utility/scripts/delete'

import Create from './components/create.vue'
import Table from './components/table.vue'

export default {
  components: {
    BFormInput,
    BCard,
    BRow,
    BCol,
    vSelect,

    Create,
    Table,
  },

  data: () => ({
    filter: {
      role: null,
    },
    searchTerm: '',
    rows: [],
    Roles,
  }),

  computed: {
    rowData() {
      return this.rows.filter(x => {
        if (this.filter.role === null) return true
        return this.filter.role === x.role
      })
    },
  },

  created() {
    this.load()
  },

  methods: {
    async toggleStatus(row) {
      const subject = `${row.username} - ${row.firstname} ${row.lastname}`

      const messageVNode = this.$createElement('div', {
        class: ['p-1'],
        domProps: {
          innerHTML: this.$t(
            `Management.Users.${row.active ? 'ActiveText' : 'DeactivateText'}`,
            { subject },
          ),
          style: 'font-size: 16px',
        },
      })

      const title = `${this.$t('Management.Users.Users')} ${this.$t(
        `Management.Users.${row.active ? 'Active' : 'Deactivate'}`,
      )
        .toString()
        .toLowerCase()}`

      const choice = await this.$bvModal.msgBoxConfirm([messageVNode], {
        title,
        size: 'sm',
        okVariant: 'primary',
        cancelVariant: 'outline-secondary',
        okTitle: this.$t(
          `Management.Users.${row.active ? 'Active' : 'Deactivate'}`,
        ),
        cancelTitle: this.$t('Global.Retreat'),
        hideHeaderClose: false,
        centered: true,
        'body-class': 'p-2',
      })
      if (!choice) {
        this.setPropertyFormUser(row.id, 'active', !row.active)
        return
      }

      try {
        await this.$axios.patch(`users/${row.id}`, { active: row.active })
      } catch {
        this.$alert.error()
        this.setPropertyFormUser(row.id, 'active', !row.active)
      }
    },
    async load() {
      try {
        const { data } = await this.$axios.get('users')
        this.rows = data
      } catch (error) {
        this.$alert.error()
      }
    },
    addEntry(entry) {
      this.rows.push(entry)
    },
    async deleteRow(row) {
      try {
        const id = await deleteEntry(this, {
          id: row.id,
          article: `${this.$t('Global.Articles_4')}`,
          subject: `${this.$t('Management.Users.Users')}`,
          individual: `${row.firstname} ${row.lastname}`,
          endpoint: 'users/',
        })
        if (id === JSON.parse(localStorage.getItem('user')).id) {
          logout()
          this.$router.push({ name: 'login' })
          return
        }
        this.rows = this.rows.filter(x => x.id !== id)
      } catch (error) {
        // continue regardless of error
      }
    },
    setPropertyFormUser(id, key, value) {
      this.rows = this.rows.map(x => {
        if (x.id !== id) return x

        return {
          ...x,
          [key]: value,
        }
      })
    },
    resetPassword(id) {
      this.setPropertyFormUser(id, 'hasPassword', false)
    },
    resetAppKey(id) {
      this.setPropertyFormUser(id, 'hasAppKey', false)
    },
  },
}
</script>
