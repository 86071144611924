<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="ml-2 d-flex align-items-center"
      variant="primary"
      @click="openSideBar"
    >
      <feather-icon class="mr-1" icon="UsersIcon" size="21" />
      {{ $tc('Global.New', 0) }} {{ $t('Management.Users.Users') }}
    </b-button>
    <Form
      ref="form"
      :user="user"
      :can="$can($acl.action.Create, $acl.subjects.Users)"
      :header="$tc('Global.New', 0) + ' ' + $t('Management.Users.Users')"
      @submit="submit"
      @reset="reset"
    />
  </div>
</template>

<script>
import { BButton, VBToggle } from 'bootstrap-vue'

import { Roles } from '@/data/enums'
import Form from './form.vue'

export default {
  components: {
    BButton,
    Form,
  },

  directives: {
    'b-toggle': VBToggle,
  },

  data: () => ({
    user: {
      username: null,
      firstname: null,
      lastname: null,
      role: null,
      email: null,
    },
    Roles,
  }),

  methods: {
    reset() {
      this.user = {
        username: null,
        firstname: null,
        lastname: null,
        role: null,
        email: null,
      }
    },
    openSideBar() {
      this.$refs.form.sidebarVisible = true
    },
    async submit() {
      try {
        const { data } = await this.$axios.post('users', {
          username: this.user.username,
          firstname: this.user.firstname,
          lastname: this.user.lastname,
          email: this.user.email,
          role: this.user.role,
          appAccess: false,
          active: true,
        })
        this.$alert.create(`${data.username}`)
        this.$emit('add', data)

        const { form } = this.$refs
        form.dirty = false
        form.sidebarVisible = false
      } catch {
        this.$alert.error()
      }
    },
  },
}
</script>
