<template>
  <span>
    <feather-icon
      v-b-tooltip.hover.top="
        $t(
          `Global.${
            $can($acl.action.Update, $acl.subjects.Users)
              ? 'EditSubject'
              : 'ViewSubject'
          }`,
          { subject: $t('Management.Users.Users') },
        )
      "
      :icon="
        $can($acl.action.Create, $acl.subjects.Users) ? 'Edit2Icon' : 'EyeIcon'
      "
      size="16"
      class="hover-primary"
      @click="openSideBar"
    />
    <Form
      ref="form"
      :user="updatedData"
      :submit-btn-text="`${$t('Global.Save')} ${$t('Management.Users.Users')}`"
      :can="$can($acl.action.Create, $acl.subjects.Users)"
      :header="$t('Management.Users.Users') + ' ' + $tc('Global.EditSubject')"
      @submit="submit"
    />
  </span>
</template>

<script>
import Form from './form.vue'

export default {
  components: {
    Form,
  },

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    updatedData: null,
  }),

  methods: {
    openSideBar() {
      this.updatedData = { ...this.user }
      this.$refs.form.sidebarVisible = true
    },
    async submit() {
      try {
        const { data } = await this.$axios.patch(
          `users/${this.user.id}`,
          this.updatedData,
        )

        this.$alert.update(data.username)

        Object.keys(this.updatedData).forEach(key => {
          this.user[key] = this.updatedData[key]
        })

        const { form } = this.$refs
        form.dirty = false
        form.sidebarVisible = false
      } catch {
        this.$alert.error()
      }
    },
  },
}
</script>
